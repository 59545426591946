




















import { Vue, Component } from 'vue-property-decorator';

export interface IBtnMenu {
    name: string;
    path: string;
}

@Component({
    name: 'MainHeader',
    components: {
    }
})
export default class MainHeader extends Vue {

    activeIndex: number | null = null;

    disableTransition: boolean = false

    btnMenus: Array<IBtnMenu> = [
        {
            name: 'Our Businese',
            path: '/ourBusiness'
        },
        {
            name: 'Our Service',
            path: '/ourService'
        },
        {
            name: 'Gallery',
            path: '/gallery'
        },
        {
            name: 'About Us',
            path: '/aboutUs'
        }
    ]

    created() {
        this.matchRoute()
    }

    matchRoute() {
        const path = this.$route.path
        this.btnMenus.forEach((item, index) => {
            if (item.path === path) {
                this.activeIndex = index
            }
        })
    }

    toPage(path: string, index: number) {
        if (this.activeIndex !== index) {
            this.disableTransition = true;
            setTimeout(() => {
                this.disableTransition = false;
            }, 30)
        }
        this.activeIndex = index;
        this.$router.push({ path });
    }

    applyOnline() {
        // @ts-ignore
        this.$parent.$parent.dialogVisible = true
    }

    goHome() {
        this.activeIndex = null;
        this.$router.push({ path: '/' })
    }
}
